<template>
  <section>
    <div class="container mt-80 mb-180">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">{{ props.title }}</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              <span v-html="props.description"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12 mt-5"
          v-for="(item, index) in props.items"
          v-bind:key="index"
        >
          <div class="features text-center">
            <div class="content mt-4">
              <div class="image position-relative d-inline-block">
                <img
                  v-bind:src="item.logoURL"
                  alt=""
                  class="avatar avatar-small"
                />
              </div>
              <h4 class="title-2" v-html="item.title"></h4>
              <div>
                <p
                  class="text-muted mb-0 text-center"
                  v-html="item.description"
                ></p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 text-center mt-5">
          <b-button variant="outline-blue text-primary">Ver mas Beneficios</b-button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    mostrar() {
      console.log("hola mundo");
    },
  },
};
</script>

<style>
.mb-180 {
  margin-bottom: 180px;
}
.mt-80 {
  margin-top: 80px;
}
</style>